








import { Component, Vue } from 'vue-property-decorator'
import PageHeader from '@/admin/components/PageHeader.vue'
import __ from '@/shared/helpers/__'
import { CompanyRoutes } from '@/shared/router/company'
import Form from '@/shared/components/form/Form.vue'
import FormBase from '@/shared/classes/form/form-base'
import ToolLocation from '@/shared/modules/tool-location/models/tool-location.model'
import Company from '@/shared/modules/company/company.model'
import { CompanyGetters } from '@/shared/store/company/company.getters'
import Field from '@/shared/classes/form/field'
import { GlobalActions } from '@/shared/store/global/global.actions'
import { SnackBarTypes } from '@/shared/helpers/snack-bar.helper'
import _ from 'lodash'

@Component({
  components: { PageHeader, Form },
  methods: { __ }
})
export default class ToolLocationsCreate extends Vue {
  form: FormBase = new FormBase()
    .setEndpoint(`company/${ this.company.slug }/tool-locations`)
    .setModel(ToolLocation)
    .setInjectValues({
      company_uuid: this.company.uuid
    })
    .setFields([
      new Field()
        .setKey('name')
        .setTitle(__('company.views.tool-locations.form.details.name'))
        .isRequired()
    ])
    .setSubmit({
      text: __('company.views.tool-locations.create.form.submit')
    })
    .setOnSuccess(this.onSuccess)

  goBack(): void {
    this.$router.push({ name: CompanyRoutes.toolLocationsIndex })
  }

  private onSuccess(): void {
    this.$store.dispatch(GlobalActions.showSnackBar, {
      type: SnackBarTypes.success,
      message: __('company.views.tool-locations.create.form.on-success'),
    })
    this.goBack()
  }

  get company(): Company {
    return this.$store.getters[CompanyGetters.getCompany]
  }
}
